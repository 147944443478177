import React from 'react'
import Image from '../hacks/image'
import { graphql, useStaticQuery } from 'gatsby'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import showdown from 'showdown'
const converter = new showdown.Converter()

export default ({ work, notsmaller, image_leftright }) => {
  const { workList } = useStaticQuery(
    graphql`
      query MyQuery {
        workList: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "//portfolio//" } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                path
                small
                metaDescription
                thumbnail {
                  relativePath
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 69) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  )
  var workShow = workList.edges.filter(function (edge) {
    if (edge.node.frontmatter.path === work) {
      return work
    } else {
      return false
    }
  }) // You can filter your posts based on some criteria

  // console.log(work, workList)
  workShow = workShow[0].node.frontmatter
  if (workShow.small && !notsmaller) {
    image_leftright = 'RIGHT'
  }
  return (
    <ParallaxProvider>
      <div
        className={`work-block ${
          !!workShow.small && !notsmaller
            ? 'col-lg-4 col-md-10 work-block-small'
            : 'col-md-12'
        }`}
      >
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center pb-5 ">
            <div
              className={`${
                !!workShow.small && !notsmaller
                  ? 'col-md-7 col-lg-8'
                  : 'col-md-8'
              } mb-4 col align-self-center ${
                image_leftright === 'RIGHT'
                  ? 'order-md-1 pull-md-2'
                  : ' push-md-2  order-md-0'
              }`}
            >
              {!!workShow.thumbnail && (
                <Parallax y={[10, -10]}>
                  <AniLink cover bg="#000" to={workShow.path}>
                    <Image
                      src={workShow.thumbnail}
                      alt={workShow.title}
                      className="img-fluid"
                    />
                  </AniLink>
                </Parallax>
              )}
            </div>
            <div
              className={`${
                workShow.small && !notsmaller
                  ? 'col-md-6 col-lg-4 hidden hidden'
                  : 'col-md-3'
              } align-middle position-unset ${
                image_leftright === 'RIGHT' &&
                (!workShow.small || (workShow.small && notsmaller))
                  ? 'offset-md-1 '
                  : ''
              }`}
            >
              <div
                className={`${
                  image_leftright !== 'RIGHT' ? 'text-md-right ' : ''
                }`}
                style={{zIndex:"+999999999", display:"block", position:"relative"}}
              >
                {workShow.title && (
                  <h2
                    className={`${
                      notsmaller ? 'h4' : workShow.small && !notsmaller && 'h4'
                    } mb-3`}
                  >
                    {workShow.title}
                  </h2>
                )}
                {(!workShow.small || (notsmaller)) && (
                  <hr className="border-primary w-100" />
                )}
                {(!workShow.small || (notsmaller)) && (
                  <div
                    className={workShow.small && !notsmaller ? 'lead' : ''}
                    dangerouslySetInnerHTML={{
                      __html: converter.makeHtml(workShow.metaDescription),
                    }}
                  />
                )}
                {workShow.path && (
                  <AniLink
                    cover
                    bg="#000"
                    to={workShow.path}
                    className="mt-4 font-weight-bold btn-link mb-4 stretched-link"
                  >
                    View
                  </AniLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  )
}
